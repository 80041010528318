import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout.js";
import Image from "../components/image.js";

const Category = ({pageContext}) => {
  const {
    name,
    children,
    icons,
    ancestors,
  } = pageContext;
  return <Layout infos={{
    title: name,
    description: "Discover and download "+ancestors[0].name+" "+name+" available icons",
    ancestors
  }}>
    { Object.keys(children).length
      ? (<>
          <h1>Categories</h1>
          <ul id="categories">
          {
            Object.values(children).map(category => {
              return <li>
                <Link
                  to={ category.slug }
                >
                  <div>
                  {
                    category.sample.map(icon => {
                      return <Image key={ icon } src={ "/"+icon } alt={ "Icon sample from "+category.name } />
                    })
                  }
                  </div>
                  <h2>{ category.name }</h2>
                  <span data-provider={ ancestors[0].slug }>
                  { category.icons.length
                    ? category.icons.length+" icons"
                    : Object.keys(category.children).length+" categories"
                  }</span>
                </Link>
              </li> 
            })
          }
        </ul>
      </>)
      : (<></>)
    }
    { icons && Object.keys(icons).length
      ? (<>
          <h1>Icons</h1>
          <ul id="icons">
          {
            Object.values(icons).map(icon => {
              return <li key={icon.path}>
                <Link
                  to={ icon.slug }
                >
                  <figure>
                    <Image src={ "/"+icon.path } alt={ icon.name } />
                    <figcaption>{ icon.name }</figcaption>
                  </figure>
                </Link>
              </li> 
            })
          }
        </ul>
      </>)
      : (<></>)
    }
  </Layout>
}

export default Category;
